export const papers = [
    
    {
        altStyle: {color: '#da6739', fontSize: '1.3rem'},
        name: 'קשיים ואתגרים בחינוך הגופני',
        url: require('./reports/sport-hardships.pdf')
    },
    {
        altStyle: {color: '#da6739', fontSize: '1.3rem'},
        img: 'https://img.icons8.com/ios/50/video--v1.png',
        name: 'תקציר לסרטון האבולוציה של החנ"ג',
        url: require('./reports/sport-evolution.pdf')
    },
    {
        name: 'גמישות – מתאוריה למעשה',
        url: require('./reports/stretching - theory to practice.pdf')
    },
    {
        name: 'ההבדלים בין נשים לגברים מבחינה אנטומית, פיסיולוגית ותפיסתית והשפעתם במעגל החיים',
        url: require('./reports/man vs woman anatomy.pdf')
    },
    {
        name: 'השפעת הפעילות הגופנית המתונה או העצימה על יכולת הריכוז',
        url: require('./reports/sport-focus.pdf')
    },
    {
        name: 'השפעת שילוב המולטימדיה בתוכנית הוראה בקרב סטודנטים',
        url: require('./reports/multimedia-students.pdf')
    },
    {
        name: 'חדר כושר כהטרוטרפיה',
        url: require('./reports/gym-hetrotherapy.pdf')
    },
    {
        name: 'חזרה לכושר לאחר פגרת החגים',
        url: require('./reports/post-holiday.pdf')
    },
    {
        name: 'טיפוח תרבות עשייה בקרב פרחי הוראה לחינוך גופני במכללת סמינר הקיבוצים,ממצאי מחקר אורך',
        url: require('./reports/active-colture.pdf')
    },
    {
        name: 'יתרונות ומגבלות חדר הכושר',
        url: require('./reports/gym-pros-cons.pdf')
    },
    {
        name: 'מתיחות – STRETCHING',
        url: require('./reports/stretching.pdf')
    },
    {
        name: 'מתווה לשחקן קט רגל',
        url: require('./reports/cat-regel.pdf')
    },
    {
        name: 'צעדים מקדימים בתוכנית לניהול עצמי בגישה הוליסטית בחדר הכושר',
        url: require('./reports/first-steps-gym.pdf')
    },
    {
        name: 'שינה – מי צריך את זה בכלל?',
        url: require('./reports/sleep.pdf')
    },
]