export const courses = [
    [{
        location: "תורת האימון במכללת גבעת וושינגטון",
        courses: [
            "מאמני כדורסל",
            "מדריכי התעמלות",
            "מדריכי שחייה",
        ]
    },
    {
        location: "תורת האימון בקמפוס שיאים באוניבריסטת תל אביב",
        courses: [
            "מדריכי אירובי",
            "מדריכי כדורגל",
            "מדריכי כדרוסל",
            "מדריכי טריאתלון",
            "מדריכי אקרובטיקה והתעמלות",
            "מאמני טריאטלון",
            "מאמני כדורסל",
        ]
    },],
    [{
        location: "מרכז ומוביל במכללת סמינר הקיבוצים",
        courses: [
            "מדריכי כושר ובריאות והכשרת קטינים לאימון בחדר הכושר (מאושר ע\"י מנהל הספורט)",
            "ניהול וארגון מפעלי ספורט",
            "כושר גופני עיוני",
            "כושר גופני מעשי",
            "מדריך פדגוגי שנה ג' בהכשרת מורים",
            "הנחיית סטאז' בחינוך הגופני שנה ד'",
            "מדריכי רכיבה טיפולית.",
            "מורה יוזם אורח חיים בריא",
            "הכשרת עוזרי הוראה בחינוך הגופני לבעלי צרכים מיוחדים (בעצ\"מ)."
        ]
    },
    {
        location: "הקריה האקדמית אונו",
        courses: [
            "מדריכי כושר ובריאות והכשרת קטינים לאימון בחדר הכושר (תורת הענף מעשי ועיוני; מאושר ע\"י מנהל הספורט).",
        ]
    },]
]   
